<template>
    <v-menu
        ref="menu"
        v-model="visibility"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value="parsedValue"
        transition="scale-transition"
        dense
        attach
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="displayValue"
                :label="label"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
            />
        </template>
        <v-time-picker
            v-if="visibility"
            v-model="parsedValue"
            :min="parsedMin"
            :max="parsedMax"
            full-width
            format="ampm"
            ampm-in-title
            color="#93BD20"
        />
    </v-menu>
</template>

<script>
import moment from "moment";

export default {
    name: "TimePicker",
    props: {
        value: {
            type: String,
            required: true,
        },
        simple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        max: {
            type: String,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            visibility: false,
        };
    },
    computed: {
        parsedValue: {
            get() {
                return this.extractTime(this.simple, this.value);
            },
            set(value) {
                const newVal = this.simple ?
                    value :
                    `2020-11-16T${value}:00`;

                this.$emit('input', newVal)
            },
        },
        parsedMin() {
            return this.min
                ? this.extractTime(this.simple, this.min)
                : null;
        },
        parsedMax() {
            return this.max
                ? this.extractTime(this.simple, this.max)
                : null;
        },
        displayValue() {
            return moment(this.value, 'YYYY-M-DTHH:mm:ss').format('hh:mm a');
        }
    },
    methods: {
        extractTime(simple, value) {
            if (simple) {
                return value;
            }

            return moment(value, 'YYYY-M-DTHH:mm:ss').format('HH:mm');
        }
    }
}
</script>

<style scoped>

</style>
