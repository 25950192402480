<template>
    <tr>
        <td class="text-center">
            <v-checkbox v-model="everyDay" />
        </td>
        <td
            v-for="day in days"
            :key="day.value"
            :class="{
                'text-center': true,
                'weekend-column': day.value === 'SUNDAY' || day.value === 'SATURDAY',
            }"
        >
            <v-checkbox v-model="value.days" :value="day.value"/>
        </td>
        <td>
            <time-picker
                v-model="value.startTimeLocal"
                label="Start"
                :max="value.endTimeLocal"
            />
        </td>
        <td>
            <time-picker
                v-model="value.endTimeLocal"
                label="Finish"
                :min="value.nextDay ? null : value.startTimeLocal"
            />
        </td>
        <td>
            <v-switch
                v-model="value.nextDay"
                label="Next Day"
                dense
                class="mt-0"
            />
        </td>
        <td>
            <v-btn
                color="#a7a7a7"
                @click="$emit('deleteTime')"
                icon
            ><v-icon>delete</v-icon></v-btn>
        </td>
    </tr>
</template>

<script>
import TimePicker from "components/product_edit/TimePicker";
export default {
    name: "TimeSelectionRow",
    components: {
        TimePicker,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        days: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            fromVisibility: false,
            toVisibility: false,
            selectedDays: [],
        }
    },
    computed: {
        everyDay: {
            get() {
                return this.value.days.length === 7;
            },
            set(value) {
                this.$emit('input',{
                    ...this.value,
                    days: value
                        ? this.days.map(x => x.value)
                        : []
                });
            }
        }
    }
}
</script>

<style scoped>
    .weekend-column {
        background: #eeeeee;
    }
</style>
