var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        "return-value": _vm.parsedValue,
        transition: "scale-transition",
        dense: "",
        attach: "",
        "max-width": "290px",
        "min-width": "290px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { label: _vm.label, readonly: "", dense: "" },
                      model: {
                        value: _vm.displayValue,
                        callback: function ($$v) {
                          _vm.displayValue = $$v
                        },
                        expression: "displayValue",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.visibility,
        callback: function ($$v) {
          _vm.visibility = $$v
        },
        expression: "visibility",
      },
    },
    [
      _vm.visibility
        ? _c("v-time-picker", {
            attrs: {
              min: _vm.parsedMin,
              max: _vm.parsedMax,
              "full-width": "",
              format: "ampm",
              "ampm-in-title": "",
              color: "#93BD20",
            },
            model: {
              value: _vm.parsedValue,
              callback: function ($$v) {
                _vm.parsedValue = $$v
              },
              expression: "parsedValue",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }