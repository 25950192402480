<template>
    <div>
        <v-radio-group
            :value="availabilityType"
            class="mt-0"
            dense
            :disabled="disabled"
            @change="onAvailabilityTypeChange"
        >
            <v-radio
                color="#93BD20"
                label="Always available"
                :value="AvailabilityType.allDay"
            />
            <v-radio
                color="#93BD20"
                label="Custom"
                :value="AvailabilityType.custom"
            />
        </v-radio-group>
        <template v-if="availabilityType === AvailabilityType.custom">
            <div>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th/>
                            <th class="pt-4 weekend-column">SAT</th>
                            <th class="weekend-column">SUN</th>
                            <th>MON</th>
                            <th>TUE</th>
                            <th>WED</th>
                            <th>THU</th>
                            <th>FRI</th>
                            <th class="datetime-column">Start</th>
                            <th class="datetime-column">Finish</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <time-selection-row
                            v-for="(_, i) of value"
                            v-model="value[i]"
                            :key="i"
                            :days="days"
                            @deleteTime="deleteTime(i)"
                        />
                        </tbody>
                    </template>
                </v-simple-table>
            </div>

            <v-row class="pt-3">
                <v-col>
                    <v-btn
                        color="#93BD20"
                        outlined
                        @click="addAnotherTime()"
                    >
                        + Add another time
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import TimeSelectionRow from "components/product_edit/time_selection_row";
import moment from "moment";

const AvailabilityType = {
    allDay: 'all-day',
    custom: 'custom',
};

export default {
    name: "TimeSelection",
    props: {
        value: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        }
    },
    components: {
        TimeSelectionRow
    },
    data() {
        return {
            days: [
                { text: 'Saturday', value: 'SATURDAY' },
                { text: 'Sunday', value: 'SUNDAY' },
                { text: 'Monday', value: 'MONDAY' },
                { text: 'Tuesday', value: 'TUESDAY' },
                { text: 'Wednesday', value: 'WEDNESDAY' },
                { text: 'Thursday', value: 'THURSDAY' },
                { text: 'Friday', value: 'FRIDAY' },
            ],
            customEntries: [
            ],
            forceSwitchToCustom: false,
        }
    },
    methods: {
        addAnotherTime() {
            this.$emit('input', [
                ...this.value,
                {
                    days: [],
                    startTimeLocal: '2020-11-16T00:00:00',
                    endTimeLocal: '2020-11-16T23:59:00',
                    nextDay: false,
                },
            ])
        },
        deleteTime(i) {
            this.$emit('input', this.value.filter((item, idx) => idx !== i));
        },
        getAvailabilityType() {
            if (!this.value) {
                return AvailabilityType.allDay;
            } else {
                if (this.value.length === 1) {
                    if (this.value[0].alwaysAvailable) {
                        return AvailabilityType.allDay;
                    }

                    if (this.value[0].days.length === 7) {
                        const startTime = moment(this.value[0].startTimeLocal, 'YYYY-M-DTHH:mm:ss').format('h:mm A');
                        const endTime = moment(this.value[0].endTimeLocal, 'YYYY-M-DTHH:mm:ss').format('h:mm A');
                        return startTime === "12:00 AM" && endTime === "11:59 PM" && this.forceSwitchToCustom === false
                            ? AvailabilityType.allDay
                            : AvailabilityType.custom;
                    }
                }

                return AvailabilityType.custom;
            }
        },
        onAvailabilityTypeChange(value) {
            this.forceSwitchToCustom = value === AvailabilityType.custom;
            this.availabilityType = value;
        },
    },
    watch: {
        value(val) {
            if (val === null || val.length === 0) {
                this.forceSwitchToCustom = false;
            }
        }
    },
    computed: {
        AvailabilityType: () => AvailabilityType,
        availabilityType: {
            get() {
                return this.getAvailabilityType();
            },
            set(val) {
                if (val === AvailabilityType.custom) {
                    this.$emit('input', [
                        {
                            days: ['SATURDAY', 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
                            startTimeLocal: '2020-11-16T00:00:00',
                            endTimeLocal: '2020-11-16T23:59:00',
                            nextDay: false,
                        }
                    ]);

                    return;
                }
                if (val === AvailabilityType.allDay) {
                    this.$emit('input', [
                        {
                            alwaysAvailable: true,
                            days: [],
                            startTimeLocal: '2020-11-16T00:00:00',
                            endTimeLocal: '2020-11-16T23:59:00',
                            nextDay: false,
                        }
                    ]);
                }
            }
        }
    }
}
</script>

<style scoped>
    .weekend-column {
        background: #eeeeee;
        font-weight: bold;
    }
    .datetime-column {
        min-width: 100px;
    }
</style>
